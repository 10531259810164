<template>
  <div class="fgg-container">
    <h1>Prüfen</h1>
    <p class="mb-4">
      Falls die Karte bereits zugewiesen ist, gibt es eine direkte Weiterleitung
      auf den entsprechende Gast.
    </p>
    <action-button
      @action="isScanning = true"
      color="secondary"
      label="Karte prüfen"
    ></action-button>
    <card-reader @success="check" @aborted="abort" v-if="isScanning" />
  </div>
</template>

<script>
import { useToast, POSITION } from "vue-toastification";
import CardReader from "../components/CardReader";
import ActionButton from "../components/form/ActionButton";
import { useRouter } from "vue-router";
import CardService from "../services/cardService";
import { mapMutations } from "vuex";

export default {
  name: "Check",

  components: { CardReader, ActionButton },

  setup() {
    return {
      cardService: new CardService(),
      toast$: useToast(),
      router: useRouter(),
    };
  },

  data: () => ({
    isScanning: false,
  }),

  computed: {
    ...mapMutations(["setCustomer", "setProfile", "setCard"]),
  },

  methods: {
    /**
     * Check read card.
     *
     * @param cardId
     */
    async check(cardId) {
      try {
        const card = await this.cardService.checkCard(cardId);
        if (card && card.customer) {
          this.setProfile(card.active_profile);
          this.setCard({ id: card.id });
          this.setCustomer(card.customer);
          this.abort();
          this.router.push(`/customer`);
        } else {
          this.abort();
          this.toast$.info("Die Karte ist niemandem zugewiesen.", {
            position: POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        this.abort();
        this.toast$.error("Die Karte ist unbekannt.", {
          position: POSITION.BOTTOM_CENTER,
        });
      }
    },

    /**
     * Abort scan.
     */
    abort() {
      this.isScanning = false;
    },
  },
};
</script>
